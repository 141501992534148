<template>
  <div>
    <p class="text-2xl mb-6">
      {{
        editing
          ? `Editer le Projet : ${project.name}`
          : "Créer un nouveau Projet"
      }}
    </p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="projectForm">
            <v-row>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="project.name"
                    dense
                    label="Nom"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    v-model="project.description"
                    outlined
                    dense
                    label="Description"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8" v-if="project.token">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    v-model="project.token"
                    dense
                    readonly="readonly"
                    disabled
                    label="Token"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveProject"
                >
                  Enregistrer le Projet</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  :to="{ name: 'rmra-projects-list' }"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { required, integerValidator } from "@core/utils/validation";
import axios from "@axios";
import router from "@/router";
export default {
  name: "ProjectCreateOrUpdate",
  async created() {
    if ("id" in this.$route.params) {
      this.getRMRAProjectById(this.$route.params.id);
      this.editing = true;
    } else {
      this.editing = false;
    }
  },
  data() {
    return {
      required,
      integerValidator,
      editing: false,
      project: {
        pk: -1,
        name: "",
        token: "",
        description: "",
      },
    };
  },
  methods: {
    async saveProject() {
      const isFormValid = this.$refs.projectForm.validate();
      if (!isFormValid) return;
      const payload = {
        name: this.project.name,
        description: this.project.description,
      };

      if (this.editing) {
        const { status } = await axios.put(
          `/rmra-projects/${this.project.pk}/`,
          payload
        );
        if (status === 200) {
          await router.push({ name: "rmra-projects-list" });
        }
      } else {
        const { status } = await axios.post(`/rmra-projects/`, payload);
        if (status === 200) {
          await router.push({ name: "rmra-projects-list" });
        }
      }
    },
    async getRMRAProjectById(id) {
      const { status, data } = await axios.get(`/rmra-projects/${id}/`);
      if (status === 200) {
        this.project = {
          pk: data.id,
          name: data.name,
          token: data.token,
          description: data.description,
        };
      }
    },
  },
};
</script>

<style scoped></style>
